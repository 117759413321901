import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {
    window.location = "/images/menu.pdf";
  }
}
